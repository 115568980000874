<template src="./AdminAcademicCategories.html"></template>

<script>
import AdminAcademicCategoriesNew from "./New/AdminAcademicCategoriesNew.vue";
import AdminAcademicCategoriesEdit from "./Edit/AdminAcademicCategoriesEdit.vue";
import categoryService from "@/services/Category";
import AdminConfirmationModal from "./../../AdminConfirmationModal/AdminConfirmationModal.vue";
export default {
  name: "admin-academic-categories",
  data() {
    return {
      categories: [],
      view: "list",
      categorySelected: null,
      openModal: false
    };
  },
  components: {
    AdminAcademicCategoriesNew,
    AdminAcademicCategoriesEdit,
    AdminConfirmationModal
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    async getCategories() {
      this.categories = await categoryService.getCategories();
    },
    setOption(option) {
      this.view = option;
      if (option === "list") {
        this.getCategories();
      }
    },
    setCategorySelected(category) {
      this.categorySelected = category;
    },
    handleModal(state) {
      this.openModal = state;
    }
  },
};
</script>

<style lang="scss" scoped src="./AdminAcademicCategories.scss"></style>

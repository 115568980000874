<template src="./AdminAcademicCategoriesEdit.html"></template>

<script>
import AdminConfirmationModal from "./../../../AdminConfirmationModal/AdminConfirmationModal.vue";
import { putFile } from "@/services/files";
import categoryService from "@/services/Category";
export default {
  name: "admin-academic-categories-edit",
  props: {
    category: { type: Object }
  },
  data() {
    return {
      categories: [],
      view: "list",
      openModal: false,
      categoryImageSelected: null,
      categoryIconSelected: null,
      categoryDescription: "",
      urlCategoryImage: null,
      urlCategoryIcon: null,
      categoryName: "",
      iconDescription: null,
    };
  },
  components: { AdminConfirmationModal },
  mounted() {
    this.categoryDescription = this.category.description;
    this.urlCategoryIcon = this.category.icon;
    this.urlCategoryImage = this.category.imagen;
    this.categoryName = this.category.name;
    this.iconDescription = this.category.iconDescription;
  },
  methods: {
    handleModal(state) {
      if (!this.categoryName.length) {
        this.$buefy.toast.open({
          duration: 5000,
          message: "Hace falta un nombre",
          position: "is-bottom",
          type: "is-danger"
        });
        this.openModal = false;
        return false;
      }
      if (!this.categoryDescription.length) {
        this.$buefy.toast.open({
          duration: 5000,
          message: "Hace falta una description de la imagen",
          position: "is-bottom",
          type: "is-danger"
        });
        this.openModal = false;
        return false;
      }
      if (!this.iconDescription.length) {
        this.$buefy.toast.open({
          duration: 5000,
          message: "Hace falta una description del icono",
          position: "is-bottom",
          type: "is-danger"
        });
        this.openModal = false;
        return false;
      }
      this.openModal = state;
    },
    async confirm() {
      this.$store.dispatch("fetchLoading", true);
      let urlImage = null;
      let urlIcon = null;
      if (this.categoryImageSelected) {
        try {
          urlImage = await putFile(
            this.categoryImageSelected,
            "Groups",
            `${this.categoryImageSelected.name}`
          );
        } catch (e) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Error cargando el archivo",
            position: "is-bottom",
            type: "is-danger"
          });
        }
      }
      if (this.categoryIconSelected) {
        try {
          urlIcon = await putFile(
            this.categoryIconSelected,
            "Groups",
            `${this.categoryIconSelected.name}`
          );
        } catch (e) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Error cargando el archivo",
            position: "is-bottom",
            type: "is-danger"
          });
        }
      }
      try {
        await categoryService.updateCategory(
          {
            name: this.categoryName ? this.categoryName : "null",
            description: this.categoryDescription
              ? this.categoryDescription
              : "null",
            imagen: urlImage ? urlImage : this.urlCategoryImage,
            icon: urlIcon ? urlIcon : this.urlCategoryIcon,
            iconDescription: this.iconDescription
              ? this.iconDescription
              : "null",
          },
          this.category.id
        );
        this.handleModal(false);
        this.categoryName = null;
        this.urlCategoryImage = null;
        this.urlCategoryIcon = null;
        this.categoryImageSelected = null;
        this.categoryIconSelected = null;
        this.categoryDescription = null;
        this.iconDescription = null;
        this.$buefy.toast.open({
          duration: 5000,
          message: "Categoría editada con éxito",
          position: "is-bottom",
          type: "is-success"
        });
      } catch (error) {
        this.$buefy.toast.open({
          duration: 5000,
          message: "Error al editadar la categoría",
          position: "is-bottom",
          type: "is-danger"
        });
        console.error(error);
      }
      this.handleModal(false);
      this.$store.dispatch("fetchLoading", false);
    }
  },
  computed: {
    categoryImage() {
      if (this.categoryImageSelected) {
        return URL.createObjectURL(this.categoryImageSelected);
      }
      if (this.urlCategoryImage) {
        return this.urlCategoryImage;
      }
      return "";
    },
    categoryIcon() {
      if (this.categoryIconSelected) {
        return URL.createObjectURL(this.categoryIconSelected);
      }
      if (this.urlCategoryIcon) {
        return this.urlCategoryIcon;
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped src="./AdminAcademicCategoriesEdit.scss"></style>

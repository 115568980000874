<template src="./AdminAcademicCategoriesNew.html"></template>

<script>
import AdminConfirmationModal from "./../../../AdminConfirmationModal/AdminConfirmationModal.vue";
import { putFile } from "@/services/files";
import categoryService from "@/services/Category";
export default {
  name: "admin-academic-categories-new",
  data() {
    return {
      categories: [],
      view: "list",
      openModal: false,
      categoryImageSelected: null,
      categoryIconSelected: null,
      categoryDescription: "",
      categoryName: "",
      iconDescription: null,
    };
  },
  components: { AdminConfirmationModal },
  methods: {
    handleModal(state) {
      if(!this.categoryName.length){
         this.$buefy.toast.open({
          duration: 5000,
          message: "Hace falta un nombre",
          position: "is-bottom",
          type: "is-danger"
        });
        this.openModal = false;
        return false;
      }
      if(!this.categoryImageSelected){
         this.$buefy.toast.open({
          duration: 5000,
          message: "Hace falta una Imagen",
          position: "is-bottom",
          type: "is-danger"
        });
        this.openModal = false;
        return false;
      }
       if(!this.categoryDescription.length){
         this.$buefy.toast.open({
          duration: 5000,
          message: "Hace falta una description de la imagen",
          position: "is-bottom",
          type: "is-danger"
        });
        this.openModal = false;
        return false;
      }
      if(!this.categoryIconSelected){
         this.$buefy.toast.open({
          duration: 5000,
          message: "Hace falta una icono",
          position: "is-bottom",
          type: "is-danger"
        });
        this.openModal = false;
        return false;
      }
      if(!this.iconDescription){
         this.$buefy.toast.open({
          duration: 5000,
          message: "Hace falta una descripcion para el icono",
          position: "is-bottom",
          type: "is-danger"
        });
        this.openModal = false;
        return false;
      }
      this.openModal = state;
    },
    async confirm() {
      this.$store.dispatch('fetchLoading', true);
      let urlImage = null;
      let urlIcon = null;
      if (this.categoryImageSelected) {
        try {
          urlImage = await putFile(
            this.categoryImageSelected,
            "Groups",
            `${this.categoryImageSelected.name}`
          );
        } catch (e) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Error cargando el archivo",
            position: "is-bottom",
            type: "is-danger"
          });
        }
      }
      if (this.categoryIconSelected) {
        try {
          urlIcon = await putFile(
            this.categoryIconSelected,
            "Groups",
            `${this.categoryIconSelected.name}`
          );
        } catch (e) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Error cargando el archivo",
            position: "is-bottom",
            type: "is-danger"
          });
        }
      }
      try {
        await categoryService.createCategory({
          name: this.categoryName,
          description: this.categoryDescription,
          imagen: urlImage,
          icon: urlIcon,
          iconDescription: this.iconDescription,
        });
        this.handleModal(false);
        this.categoryName = null;
        this.categoryImageSelected = null;
        this.categoryIconSelected = null;
        this.categoryDescription = null;
        this.iconDescription = null;
        this.$buefy.toast.open({
          duration: 5000,
          message: "Categoría creada con éxito",
          position: "is-bottom",
          type: "is-success",
        });
      } catch (error) {
        this.$buefy.toast.open({
          duration: 5000,
          message: "Error al guardar la categoría",
          position: "is-bottom",
          type: "is-danger"
        });
        console.error(error);
      }
      this.handleModal(false);
      this.$store.dispatch('fetchLoading', false);
    }
  },
  computed: {
    categoryImage() {
      if (this.categoryImageSelected) {
        return URL.createObjectURL(this.categoryImageSelected);
      }
      return "";
    },
    categoryIcon() {
      if (this.categoryIconSelected) {
        return URL.createObjectURL(this.categoryIconSelected);
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped src="./AdminAcademicCategoriesNew.scss"></style>
